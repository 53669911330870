<template>
  <div class="personalCenter">
    <div class="main">
      <div class="main_head">
        <div class="userName">{{ userInfo.name }}</div>
        <div class="deptTile"></div>
      </div>
      <div class="main_basicInfo">
        <div class="basicInfo_title">Basic Info.</div>
        <div class="basicInfo_data">
          <p>
            <label>Name：</label>
            <span>{{ userInfo.name?userInfo.name:'--' }}</span>
          </p>
          <p>
            <label>Phone：</label>
            <span>{{ userInfo.mobile?userInfo.mobile:'--' }}</span>
          </p>
          <p>
            <label>Email：</label>
            <span>{{ userInfo.email?userInfo.email:'--' }}</span>
          </p>
          <p>
            <label>Partner Name：</label>
            <span>{{ userInfo.channelName?userInfo.channelName:'--' }}</span>
          </p>
          <p>
            <label>Dept. Name：</label>
            <span>{{ userInfo.deptName?userInfo.deptName:'--' }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "personalCenter",
  data() {
    return {
      userInfo: {},
    }
  },
  created(){
    this.userInfo=JSON.parse(localStorage.getItem('library_userInfo'))
  }
};
</script>

<style lang="scss" scoped>
.personalCenter {
  width: 100%;
  padding: 40px 500px;
  .main {
    width: 100%;
    background: #FFF;
    padding: 30px;
    min-height: 600px;
    .main_head{
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #ccc;
        padding: 0 10px;
        .userName{
            font-size: 26px;
            padding-bottom: 10px;
        }
        .deptTile{
            color: #7A7B80;
            margin-left: 20px;
        }
    }
    .main_basicInfo{
        width: 100%;
        padding: 20px 20px;
        .basicInfo_title{
            width: 100%;
            font-size: 18px;
            padding: 10px 0;
        }
        .basicInfo_data{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            p{
                width: 50%;
                padding: 15px 0;
                color: #7A7B80;
            }
        }
    }
  }
}
</style>